import { splitProps } from "solid-js";
import { HeroSectionProps } from "../../model";
export function HeroSection(props: HeroSectionProps) {
	const [local, others] = splitProps(props, ["title"]);
	return (
		<section {...others} class="flex flex-col bg#p py-220px justify-center items-center text-white">
			<p class="w-1007px text-center text-76px font-bold leading-84px ">{local.title}</p>
		</section>
	);
}
